<template lang="pug">
  .me_wrapper
    van-cell-group
      van-cell(title="店铺管理列表"  is-link  to="shop/manage")
      van-cell(title="发货地址列表"  is-link to="address/list")
      van-cell(title="合并订单" center )
        template(#right-icon)
          van-switch(:value="mergeOrderFlag" size="24" active-color="#dd4433" @change="changeMerge")
      van-cell(title="版本信息"  :value="apiVersion")
</template>

<script>
import utils from "@/common/utils";
import {mapGetters} from "vuex";

export default {
  name: "index",
  data() {
    return {
      apiVersion: '1.0.0'
    }
  },
  computed: {
    ...mapGetters(['mergeOrderFlag'])
  },
  created() {
    this.apiVersion = utils.get('apiVersion');
  },
  methods: {
    changeMerge(v) {
      this.$store.commit('changeMergeOrderFlag', v);
    }
  }
}
</script>

<style scoped lang="stylus">
.me_wrapper
  .van-cell
    padding 10px 16px

  .van-switch
    box-sizing: content-box;

</style>
